
.InitialSignup{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Cairo', ui-sans-serif, system-ui, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
}

.InitialSignup .contentDiv{
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  width: 35%;
  min-width: 300px;
}

.InitialSignup h1{
  font-size: 25px ;
  width: 100%;
}

.InitialSignup form{
  width: 100%;
  /* border: 1px solid  black; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.InitialSignup form div{
  /* border: 1px solid  black; */
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.InitialSignup div input{
  padding: 8px 5px;
  outline: none;
  border-radius: 10px;
  background-color: #e8f0fe;
  border: 1px solid #e5e7eb;
}
.InitialSignup div input:focus{
  border: 1px solid #2563eb;
}

.InitialSignup div input[type="submit"]{
  cursor: pointer;
  background-color: #2563eb;
  color: white;
  font-weight: bold;
}

.InitialSignup div input[type="submit"]:hover{
  background-color: #204cdc;
}

.InitialSignup div p{
  font-weight: bold;
}

.InitialSignup div p span{
  color: #2563eb;
  cursor: pointer;
}